import { render, staticRenderFns } from "./ImportMapsBroker.vue?vue&type=template&id=12fdd802&scoped=true&"
import script from "./ImportMapsBroker.vue?vue&type=script&lang=js&"
export * from "./ImportMapsBroker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fdd802",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12fdd802')) {
      api.createRecord('12fdd802', component.options)
    } else {
      api.reload('12fdd802', component.options)
    }
    module.hot.accept("./ImportMapsBroker.vue?vue&type=template&id=12fdd802&scoped=true&", function () {
      api.rerender('12fdd802', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tables/ImportMapsBroker.vue"
export default component.exports