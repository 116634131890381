var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "", id: "collapse-1" } },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch(_vm.filterName)
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "insurers",
                                label: "Compagnie",
                                options: _vm.companies,
                              },
                              model: {
                                value: _vm.filter.byInsurer.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byInsurer, "id", $$v)
                                },
                                expression: "filter.byInsurer.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "mappable_id",
                                label: "Mappato",
                                options: _vm.opt_map,
                              },
                              model: {
                                value: _vm.filter.byImport,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "byImport", $$v)
                                },
                                expression: "filter.byImport",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-input", {
                              attrs: {
                                name: "map",
                                type: "text",
                                label: "Codice Compagnia",
                                placeholder: "Inserisci un codice compagnia",
                              },
                              model: {
                                value: _vm.filter.byAttribute.map,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byAttribute, "map", $$v)
                                },
                                expression: "filter.byAttribute.map",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-1",
                                    arg: "collapse-1",
                                  },
                                ],
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter(_vm.filterName)
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("import-maps", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              resource: _vm.resource,
              filterName: _vm.filterName,
              filterOn: {
                byRelations: ["byInsurer"],
              },
              onlyActions: ["infomodal"],
            },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }