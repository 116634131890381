var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Caricamento in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-1",
                      attrs: {
                        header: "Dati Principali",
                        "header-tag": "header",
                      },
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-4 border-bottom" },
                              [_vm._v("Codice Compagnia")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8 border-bottom" },
                              [_vm._v(_vm._s(_vm.dbRowData.map))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-4 border-bottom" },
                              [_vm._v("Compagnia")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8 border-bottom" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.dbRowData["insurer"]
                                        ? _vm.dbRowData.insurer.formatted_title
                                        : ""
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.dbRowData.mappable_id
                    ? _c(
                        "div",
                        [
                          _c("validation-observer", {
                            ref: "observer",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ invalid, handleSubmit }) {
                                    return [
                                      _c(
                                        "b-form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              staticClass: "mt-1",
                                              attrs: {
                                                header:
                                                  "Mappatura Compagnie Coass - associa una Compagnia Coass",
                                                "header-tag": "header",
                                              },
                                            },
                                            [
                                              _c("b-card-text", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-8",
                                                  },
                                                  [
                                                    _c("base-select", {
                                                      attrs: {
                                                        name: "insurer_participants",
                                                        vid: "insurer_participants",
                                                        label:
                                                          "Compagnie Coass",
                                                        options:
                                                          _vm.opt_insurer_participant,
                                                        rules: {
                                                          required: true,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.mappable_id,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "mappable_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.mappable_id",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "py-2 mb-4" },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    type: "button",
                                                    disabled: invalid,
                                                    variant: "lisaweb",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return handleSubmit(
                                                        _vm.onUpdate
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Associa\n              "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2577090806
                            ),
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "b-card",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                header: "Compagnia Coass",
                                "header-tag": "header",
                              },
                            },
                            [
                              _c(
                                "b-card-text",
                                [
                                  _c("b-row", [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4 border-bottom" },
                                      [_vm._v("Compagnie Coass")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8 border-bottom" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getInsurerParticipant(
                                                _vm.dbRowData
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }