var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Identificativi",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "salesmen",
                                                          name: "salesmen",
                                                          label:
                                                            _vm.getDictionary(
                                                              "salesman"
                                                            ),
                                                          options:
                                                            _vm.opt_salesmen,
                                                          rules: {
                                                            required: true,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .mappable_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "mappable_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.mappable_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "map",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].map.label,
                                                          label:
                                                            "Codice Produttore di Compagnia",
                                                          rules: {
                                                            required: true,
                                                          },
                                                          disabled: true,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .map,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "map",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].map",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ]
                                                            .insurance_ancillary_migrate
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .insurance_ancillary_migrate
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .insurance_ancillary_migrate
                                                              .options,
                                                          rules: {
                                                            required: true,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .insurance_ancillary_migrate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "insurance_ancillary_migrate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].insurance_ancillary_migrate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.form[_vm.rep]
                                                    .insurance_ancillary_migrate ===
                                                  "Y"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c(
                                                            "base-datepicker",
                                                            {
                                                              attrs: {
                                                                name: _vm
                                                                  .beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .insurance_ancillary_date
                                                                  .label,
                                                                label:
                                                                  _vm.beForm[
                                                                    _vm.rep
                                                                  ]
                                                                    .insurance_ancillary_date
                                                                    .label,
                                                                rules: {
                                                                  required: true,
                                                                },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form[
                                                                    _vm.rep
                                                                  ]
                                                                    .insurance_ancillary_date,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form[
                                                                        _vm.rep
                                                                      ],
                                                                      "insurance_ancillary_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form[rep].insurance_ancillary_date",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].book_entry_migrate
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .book_entry_migrate
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .book_entry_migrate
                                                              .options,
                                                          rules: {
                                                            required: true,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .book_entry_migrate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "book_entry_migrate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].book_entry_migrate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.form[_vm.rep]
                                                    .book_entry_migrate === "Y"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c(
                                                            "base-datepicker",
                                                            {
                                                              attrs: {
                                                                name: _vm
                                                                  .beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .book_entry_date
                                                                  .label,
                                                                label:
                                                                  _vm.beForm[
                                                                    _vm.rep
                                                                  ]
                                                                    .book_entry_date
                                                                    .label,
                                                                rules: {
                                                                  required: true,
                                                                },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form[
                                                                    _vm.rep
                                                                  ]
                                                                    .book_entry_date,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form[
                                                                        _vm.rep
                                                                      ],
                                                                      "book_entry_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form[rep].book_entry_date",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2457138128
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }