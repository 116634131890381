<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <b-card header="Dati Principali" header-tag="header" class="mt-1">
          <div
            slot="header"
            class="d-flex justify-content-between align-items-center"
          >
            <p class="mb-0 d-inline-block align-middle">
              <b>Dati Principali</b>
            </p>
            <b-btn
              variant="lisaweb"
              size="sm"
              @click="migrate.click()"
              v-if="migrate.click"
            >
              {{ migrate.label }}
            </b-btn>
          </div>
          <b-card-text>
            <b-row>
              <div class="col-md-4 border-bottom">
                Codice Produttore di Compagnia
              </div>
              <div class="col-md-8 border-bottom">{{ dbRowData.map }}</div>
            </b-row>
            <b-row>
              <div class="col-md-4 border-bottom">Compagnia</div>
              <div class="col-md-8 border-bottom">
                {{
                  dbRowData["insurer"] ? dbRowData.insurer.formatted_title : ""
                }}
              </div>
            </b-row>
          </b-card-text>
        </b-card>

        <div v-if="!dbRowData.mappable_id">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card
                header="Mappatura Produttori - associa produttore"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <div class="form-group col-md-8">
                    <base-select
                      name="salesmen"
                      vid="salesmen"
                      label="Produttori Lisaweb"
                      v-model="form.mappable_id"
                      :options="opt_salesmen"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onUpdate)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Associa
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          <b-card header="Produttore" header-tag="header" class="mt-1">
            <b-card-text>
              <b-row>
                <div class="col-md-4 border-bottom">Produttori Lisaweb</div>
                <div class="col-md-8 border-bottom">
                  {{ dbRowData.mappable.attributables?.BUID }} -
                  {{
                    dbRowData.mappable.attributables?.NAME
                      ? dbRowData.mappable.attributables?.NAME +
                        " " +
                        dbRowData.mappable.attributables?.SURN
                      : dbRowData.mappable.attributables?.CNAM
                  }}
                </div>
              </b-row>
            </b-card-text>
          </b-card>
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Caricamento in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { mapGetters } from "vuex";
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";

export default {
  name: "General",
  mixins: [FormMixin, ShowMixin, ShortcutMixin],
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseIcon,
    BaseSelect,
  },
  data() {
    return {
      repository: "import_map",
      isLoading: false,
      opt_salesmen: [],
      form: {
        mappable_id: null,
        import_map: {
          insurer_id: null,
          broker: {},
          map: null,
        },
      },
      migrate: {},
    };
  },
  methods: {
    onUpdate() {
      this.isLoading = true;
      this.setMandatoryField();
      this.update(this.repository, this.resourceId)
        .then(() => {
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    setMandatoryField() {
      this.form[this.repository].insurer_id = this.dbRowData.insurer_id;
      this.form[this.repository].map = this.dbRowData.map;
      this.form[this.repository].broker = {
        [this.form.mappable_id]: {},
      };
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
    }),
  },
  mounted() {
    this.opt_salesmen = this.getSalesmen();
    if (this.canVerb("import_maps", "edit") && this.dbRowData.mappable_id) {
      this.migrate = {
        label: "Migra",
        click: () => {
          this.shortcut(
            "import_maps.transfer",
            this.resourceId,
            null,
            null,
            null,
            null,
            null,
            { details: this.dbRowData }, // mappable_id siccome è il campo "polimorfo", non viene inviato dalla edit: in import_maps.transfer o chiamo la index, o gli passo dbRowData dal router
            this.$options.name
          );
          // this.shortcut(
          //   "import_maps.transfer",
          //   this.resourceId,
          //   null,
          //   null,
          //   null,
          //   {
          //     /* fromShortcut: true */
          //   },
          //   [
          //     {
          //       text: "Profilo",
          //       path: "",
          //       tab: "",
          //       level: 0,
          //       disabled: true,
          //     },
          //     {
          //       text: "Mappatura Importatori",
          //       path: "module.PROF.CONF",
          //       tab: "",
          //       level: 1,
          //     },
          //     {
          //       text: "Mappatura Produttori",
          //       path: "module.PROF.CONF",
          //       tab: "#Brokers",
          //       level: 2,
          //     },
          //   ],
          //   { details: this.dbRowData }, // mappable_id siccome è il campo "polimorfo", non viene inviato dalla edit: in import_maps.transfer o chiamo la index, o gli passo dbRowData dal router
          //   this.$options.name
          // );
        },
      };
    }
  },
};
</script>
